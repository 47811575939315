<template>
  <div id="Manage">
    <!-- 商城模块中订单管理   -->
    <!-- Dialog 对话框 审核表单-->
    <el-dialog :title="dialogFonfigAudit.auditName"
               class="dialogAudit"
               :close-on-click-modal="false"
               :visible.sync="dialogFonfigAudit.isShowDialogVisible">
      <CommonForm :form="auditForm"
                  class="dialogAuditForm"
                  :formLabel="auditFormLabel"
                  inline></CommonForm>
      <div slot="footer"
           class="dialog-footer">
        <el-button @click="clickCancelData">取 消</el-button>
        <el-button type="primary"
                   @click="clickSubmitAuditData(auditForm)">保 存</el-button>
      </div>
    </el-dialog>
    <CommonManageMain>
      <template v-slot:header>
        <div>

        </div>
        <CommonForm :form="searchForm"
                    :formLabel="searchformLabel"
                    ref="select"
                    class="filtrateInput">
          <el-button type="primary"
                     @click="searchHotelProductToAudit(searchForm)">搜 索</el-button>
          <el-button>导出</el-button>
          <el-button type="text">查看已导出列表</el-button>
          <el-button type="text"
                     @click="resetFilter(searchForm)">重置筛选条件</el-button>
        </CommonForm>

      </template>

      <!-- 表格内容框 -->
      <template v-slot:content>
        <CommonTabForm class="content"
                       :tabLabel="tableFormLabel"
                       :tableData="tableData"
                       @clickExpressData="clickAuditData"
                       @clickDetailData='clickDetailData'
                       @clickInfoData='clickInfoData'
                       @tabHandleClick="swichTable"
                       @getPage="getPage"
                       @getNumArticle="getNumArticle"></CommonTabForm>
      </template>
    </CommonManageMain>
  </div>
</template>

<script>

import {
  getShopOrder, deliveryOrderDetail, shippedOrder
} from 'api/shopMoudle.js'

import CommonForm from 'components/CommonForm.vue'
import CommonTable from 'components/CommonTable.vue'
import CommonManageMain from 'components/CommonManageMain.vue'
import CommonTabForm from 'components/CommonTab_Form.vue'
import CommonAmap from 'components/CommonAmap.vue'

export default {
  components: {
    CommonForm,
    CommonTable,
    CommonManageMain,
    CommonTabForm,
    CommonAmap
  },
  data () {
    return {
      // 主页面组件布局
      imgIndex: [], // 获取删除后的图片
      searchData: '', // 存放搜索的数据，分页时使用
      tableFormLabel: [
        {
          name: 'first',
          label: '储备列表',
          type: 'shopTable',
          tableLabel: [
            {
              prop: 'no',
              // label: '订单编号',
              // width: '200px',
              type: 'shop'
            }
          ],
          tableConfig: {
            isShowLoading: true,
            // 改造状态列显示效果 el-switch
            page: 1,
            total: 30,
            NumArticle: 10,
            // 加载状态
            loading: true,
            // 显示 选择多行数据
            selection: true
          }
        }

      ],

      // 对话框 Dialog 审核酒店民宿
      dialogFonfigAudit: {
        auditName: '待审核',
        isShowDialogVisible: false
      },

      // form表单 快递信息
      auditForm: {
        id: '',
        express_com_id: '',
        express_num: ''
      },
      auditFormLabel: [
        {
          model: 'id',
          label: '订单ID',
          width: '400px'
        },
        {
          model: 'express_com_id',
          label: '快递公司ID',
          width: '400px'
        },
        {
          model: 'express_num',
          label: '快递单号',
          width: '400px'
        }
      ],

      // form表单 搜索数据
      searchForm: {
        no_like: '',
        creat_time: [],
        real_name: '',
        mobile: '',
        buy_type: '',
        type: ''
      },
      searchformLabel: [
        {
          model: 'no_like',
          label: '订单编号',
          width: '304px'
        },
        {
          model: 'creat_time',
          label: '下单时间',
          width2: '200px',
          type: 'datePicker'
        },
        {
          model: 'real_name',
          label: '收货人姓名',
          width: '304px'
        },
        {
          model: 'mobile',
          label: '收货人手机',
          width: '304px'
        },
        {
          model: 'buy_type',
          label: '支付方式',
          width: '304px',
          type: 'select',
          options: [
            { value: 1, label: '微信支付' },
            { value: 2, label: '支付宝' },
            { value: 3, label: '余额支付' },
            { value: 4, label: '旅游金' },
            { value: 5, label: '积分' },
            { value: 6, label: '去和支付' },
            { value: 7, label: '银联支付' }
          ]
        },
        {
          model: 'type',
          label: '订单类型',
          type: 'select',
          width: '250px',
          placeholder: '请选择',
          options: [
            { value: 1, label: '抢购订单' },
            { value: 2, label: '拼团订单' },
            { value: 3, label: '普通订单' }
          ]
        }
      ],

      // table 表格 基础数据
      tableData: [],
      // 全部-数据
      hotelAllDataObj: {},
      // catgory_id最大值
      MaxId: '',
      // 待审核 或 正式运营 Name
      activeTableName: 'first',
      // Dialog新增/编辑 Name
      activeTabName: 'first',
      // Dialog 放大图片
      dialogImgVisible: false,
      dialogImageUrl: '',

      // 提交保存信息的 按钮名称
      dialogBtnName: '下一步'
    }
  },
  beforeRouteEnter (to, from, next) {
    if (from.name == 'shopOrderDetail') {
      to.meta.isBack = true
      // 判断是从哪个路由过来的，
      // 如果是page2过来的，表明当前页面不需要刷新获取新数据，直接用之前缓存的数据即可
    }
    // 继续执行
    next()
  },
  activated () {
    if (!this.$route.meta.isBack || this.isFirstEnter) {
      // 如果isBack是false，表明需要获取新数据，否则就不再请求，直接使用缓存的数据
      // 如果isFirstEnter是true，表明是第一次进入此页面或用户刷新了页面，需获取新数据
      // 执行自己写的页面的初始化
      this.updataTableData()
    }
    // 恢复成默认的false，避免isBack一直是true，导致下次无法获取数据
    this.$route.meta.isBack = false
    // 恢复成默认的false，避免isBack一直是true，导致每次都获取新数据
    this.isFirstEnter = false
  },
  created () {
    this.isFirstEnter = true
    // 只有第一次进入或者刷新页面后才会执行此钩子函数，使用keep-alive后（2+次）进入不会再执行此钩子函数
  },
  mounted () {
    // 初始table数据
    console.log(this.tableData)
    this.getShopOrder()
  },

  watch: {
    // 将字段转换为Elementui能用的字段
    hotelAllDataObj: function () {
      this.tableData = this.dataFormatDeal(
        this.hotelAllDataObj.list,
        {
          status: 'status'
        },
        0
      )
    },
    activeTabName: function () {
      if (this.activeTabName == 'fourth') {
        this.dialogBtnName = '保 存'
      } else {
        this.dialogBtnName = '下一步'
      }
    }
  },
  methods: {

    // 获取商品订单接口
    getShopOrder (data) {
      if (data) {
        getShopOrder(data).then(res => {
          const DATA = res.data
          if (DATA.code == 200) {
            this.$message({
              message: DATA.message,
              type: 'success'
            })
            this.hotelAllDataObj = DATA.data

            this.tableFormLabel[0].tableConfig.total = DATA.data.total
            this.tableFormLabel[0].tableConfig.loading = false
            console.log(this.hotelAllDataObj)
          }
        })
      } else {
        getShopOrder({
          page: this.tableFormLabel[0].tableConfig.page,
          page_rows: this.tableFormLabel[0].tableConfig.NumArticle
        }).then(res => {
          console.log(res)
          const DATA = res.data
          if (DATA.code == 200) {
            this.$message({
              message: DATA.message,
              type: 'success'
            })
            this.hotelAllDataObj = DATA.data

            this.tableFormLabel[0].tableConfig.total = DATA.data.total
            this.tableFormLabel[0].tableConfig.loading = false
            console.log(this.hotelAllDataObj)
          }
        })
      }
    },

    // 订单详单发货信息设置  接口
    deliveryOrderDetail (data) {
      return new Promise(resolve => {
        deliveryOrderDetail(data).then((res) => {
          const DATA = res.data
          if (DATA.code == 200) {
            this.$message({
              message: DATA.message,
              type: 'success'
            })
            resolve(res)
          } else {
            this.$message({
              message: DATA.message,
              type: 'warning'
            })
          }
        })
      })
    },
    // 获得下列分类页的页数
    getPage () {
      if (this.activeTableName == 'first') {
        if (this.searchData) {
          this.searchData.page = this.tableFormLabel[0].tableConfig.page
          this.searchData.page_rows = this.tableFormLabel[0].tableConfig.NumArticle
          this.getShopOrder(this.searchData)
        } else {
          this.getShopOrder()
        }
      } else if (this.activeTableName == 'second') {
        if (this.searchData) {
          this.searchData.page = this.tableFormLabel[1].tableConfig.page
          this.searchData.page_rows = this.tableFormLabel[1].tableConfig.NumArticle
          // this.getHotelProductToOperation(this.searchData)
        } else {
          // this.getHotelProductToOperation()
        }
      }
    },

    // 获取下面分页的页数的最大条数
    getNumArticle (NumArticle) {
      this.tableFormLabel[0].tableConfig.NumArticle = NumArticle
      this.tableFormLabel[1].tableConfig.NumArticle = NumArticle
      if (this.activeTableName == 'first') {
        if (this.searchData) {
          this.searchData.page = this.tableFormLabel[0].tableConfig.page
          this.searchData.page_rows = this.tableFormLabel[0].tableConfig.NumArticle
          this.getShopOrder(this.searchData)
        } else {
          this.getShopOrder()
        }
      } else if (this.activeTableName == 'second') {
        if (this.searchData) {
          this.searchData.page = this.tableFormLabel[1].tableConfig.page
          this.searchData.page_rows = this.tableFormLabel[1].tableConfig.NumArticle
          // this.getHotelProductToOperation(this.searchData)
        } else {
          // this.getHotelProductToOperation()
        }
      }
    },

    // 数据格式处理
    dataFormatDeal (arr, keyMap, copyInKeyMap) {
      // global 全局方法处理
      return this.globalDeepCloneData(arr, keyMap, copyInKeyMap)
    },

    // 获取最大的 catgory_id值
    MaxCatgroyId (arr, keyMap) {
      let maxid = 0
      for (const val of arr) {
        maxid = maxid > val[keyMap] ? maxid : val[keyMap]
      }
      return maxid
    },

    // 切换tab 待审核、正式运营
    swichTable (tab) {
      const name = tab.name
      this.activeTableName = name

      // 刷新
      this.updataTableData()
    },
    // 切换tab Dialog新增、编辑
    swichTab (tab) {
      const name = tab.name
      this.activeTabName = name
    },
    // 刷新表格数据
    updataTableData () {
      console.log(this.activeTableName)

      if (this.activeTableName == 'first') {
        // 带审核
        this.getShopOrder()
      } else if (this.activeTableName == 'second') {
        // 正式运营
        // this.getHotelProductToOperation();

      } else {
        console.log('出错了！')
      }
    },

    // 添加数据操作
    clickAddData () {
      this.openForm = this.$options.data().openForm
      this.dialogFonfig.isShowDialogVisible = true
      this.dialogFonfig.openType = 'add'
      // console.log(this.$refs.elTable.page)
    },

    // 取消提交数据操作
    clickCancelData () {
      this.dialogFonfigAudit.isShowDialogVisible = false
      // 用于当前 Vue 实例的初始化选项
      this.auditName = this.$options.data().auditName
      this.dialogFonfigAudit.isShowDialogVisible = false
    },

    // 查看详情按钮
    clickDetailData (row1, row2) {
      console.log(row1, row2)
      // row1 获取订单编号
      // row2 不同供应商的订单信息
      this.$router.push({ name: 'shopOrderDetail', query: { order_id: row2.id } })
      var str1 = JSON.stringify(row1)
      var str2 = JSON.stringify(row2)
      sessionStorage.setItem('orderDetail', str1)
      sessionStorage.setItem('supplierDetail', str2)
    },

    // 设置快递信息
    clickAuditData (row1, row2) {
      // row1 订单ID
      // row2 供应商的快递信息
      console.log(row1, row2)
      this.auditForm = {
        id: row1.id,
        express_com_id: row2.express_com_id,
        express_num: row2.express_com_id
      }
      this.dialogFonfigAudit.isShowDialogVisible = true
    },
    // 设置快递信息提交
    async clickSubmitAuditData (data) {
      // ----------------数据格式-----------------
      // id	是	int	ID
      const DATA = {
        id: data.id,
        express_com_id: data.express_com_id,
        express_num: data.express_com_id
      }
      await this.deliveryOrderDetail(DATA).then(async (res) => {
        if (res.code == 200) {
          await this.updataTableData()
        }
      })// 调用审核接口

      this.dialogFonfigAudit.isShowDialogVisible = false
    },
    // 设置发货按钮
    clickInfoData (row) {
      console.log(row)
      // ----------------数据格式-----------------
      // id	是	int	ID
      this.$confirm('确定要发货吗？', '提示', { type: 'success' }).then(() => {
        const DATA = {
          id: row.id
        }
        shippedOrder(DATA).then((res) => {
          const DATA = res.data
          if (DATA.code == 200) {
            console.log(res)
            this.$message.success('发货成功！')
          } else {
            console.log(res)
            this.$message({
              type: 'error',
              message: res.data.message
            })
          }
          this.updataTableData()
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消发货'
        })
      })
    },

    // 查询操作
    searchHotelProductToAudit (searchForm) {
      console.log(searchForm.creat_time[0], searchForm.creat_time[1])
      if (searchForm.creat_time[0] == undefined) {
        searchForm.creat_time[0] = 27221433000
        searchForm.creat_time[1] = 32530901433000
      }
      const data = {
        no_like: searchForm.no_like,
        creat_time: [searchForm.creat_time[0] / 1000, searchForm.creat_time[1] / 1000],
        real_name: searchForm.real_name,
        mobile: searchForm.mobile,
        buy_type: searchForm.buy_type,
        type: searchForm.type
      }

      this.searchData = data
      console.log(data)
      if (this.activeTableName == 'first') {
        data.page = this.tableFormLabel[0].tableConfig.page
        data.page_rows = this.tableFormLabel[0].tableConfig.NumArticle
        this.getShopOrder(data)
      } else if (this.activeTableName == 'second') {
        data.page = this.tableFormLabel[1].tableConfig.page
        data.page_rows = this.tableFormLabel[1].tableConfig.NumArticle
        // this.getHotelProductToOperation(data)
      }
      if (searchForm.creat_time[1] == 32530901433000) {
        searchForm.creat_time[0] = undefined
        searchForm.creat_time[1] = undefined
      }
    },
    // 重置筛选条件操作
    resetFilter (searchForm) {
      searchForm.no_like = '',
        searchForm.creat_time = '',
        searchForm.real_name = '',
        searchForm.mobile = '',
        searchForm.buy_type = '',
        searchForm.type = '',
        this.searchData = ''
      if (this.activeTableName == 'first') {
        this.getShopOrder()
      } else if (this.activeTableName == 'second') {
        // this.getHotelProductToOperation()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#Manage {
  display: flex;
  display: -webkit-flex;
  // 主轴方向
  flex-direction: column;
  height: 100%;
  .el-transfer {
    text-align: center;
    /deep/ .el-transfer-panel {
      text-align: left;
    }
  }
  .content {
    height: 100%;
  }
  /deep/ .el-tabs__content {
    height: 806px;
  }
  .dialogAudit {
    /deep/ .el-dialog__body {
      padding-right: 45%;
    }
  }
  /deep/ .el-table .cell {
    margin-left: -60px;
    margin-top: -7px;
    padding-right: 0px;
  }
  /deep/ .el-table {
    margin-top: 7px;
  }
  /deep/ .el-table td {
    padding-bottom: 5px;
  }
  /deep/ .el-table__header-wrapper {
    display: none;
  }
  /deep/ .el-table .cell.el-tooltip {
    white-space: normal;
    text-align: left;
  }
  .filtrateInput {
    background: #f8f7f7;
    padding: 24px 8px;
    margin-top: 10px;
    width: 100%;
    margin-left: -128px;
    margin-bottom: 32px;
  }
  /deep/ .el-checkbox:last-of-type {
    display: none;
  }
  /deep/ .cell .el-tooltip {
    width: 1340px;
  }
  /deep/ .el-tabs__nav-scroll {
    display: none;
  }
}
</style>
